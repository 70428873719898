(function ( window ) {
  var SCRIPT_VERSION = '1.3.0';

  function getPlatform ( callback ) {
    var fanplayr = window.fanplayr;
    if ( fanplayr && fanplayr.platform ) {
      fanplayr.platform.once('ready', { init: true }, function () {
        callback(fanplayr);
      });
    } else {
      var fanplayr_ready = window.fanplayr_ready;
      window.fanplayr_ready = function ( fanplayr ) {
        callback(fanplayr);
        if ( fanplayr_ready ) {
          fanplayr_ready.apply(null, arguments);
        }
      };

      if ( !fanplayr || !fanplayr._loadState ) {
        var script = document.createElement("script");
        script.src = "https://cdn.fanplayr.com/client/production/loader.js";
        script.async = true;
        var entry = document.getElementsByTagName("script")[0];
        entry.parentNode.insertBefore(script, entry);
      }
    }
  }

  var legacyOrder = window.fp_sales_orders;
  if ( legacyOrder ) {
    legacyOrder.scriptVersion = SCRIPT_VERSION;
    getPlatform(function ( fanplayr ) {
      fanplayr.platform.trackOrder();
    });
  }
}(window));
